import Link from "next/link";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CookieIcon from "./icons/CookieIcon";
import { device } from "../layout/global-styles/device";

const UpdateCookies = () => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(localStorage.getItem("show_cookies_popup") === "false");
    }, []);

    return (
        <>
            {show && (
                <Wrapper>
                    <Link href={"/informasjonskapsler#administrering"} passHref>
                        <IconWrapper>
                            <CookieIcon height={47} width={50} fill={"#000"} />
                        </IconWrapper>
                    </Link>
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    //display: none;
    position: fixed;
    margin-top: 100vh;
    bottom: 3%;
    left: 3%;
    height: 50px;
    width: 50px;
    border: 1px solid ${(props) => props.theme.black};
    border-radius: 50%;
    z-index: 100;
    background: ${(props) => props.theme.white};
    transition: 250ms;

    &:hover {
        background: ${(props) => props.theme.primary};
    }

    @media ${device.laptopL} {
        bottom: 2%;
        left: 2%;
    }
`;

const IconWrapper = styled.a``;

export default UpdateCookies;
