const CookieIcon = ({ fill, width, height }) => {
    return (
        <svg viewBox="0 0 60 60" height={height} width={width} fill={fill}>
            <g id="Picto">
                <path
                    d="M34.44,26.48a8.25,8.25,0,0,0,2.88-.43l1.2,1.34c0,2.69,1.1,3.61,4.08,3.61l1,1c-.29,8.12-5.81,13.16-14.16,13.16S15.67,39.78,15.67,31.43c0-8.16,5-14,13.1-14.31l1.3,1.3a11,11,0,0,0-.53,3.21C29.54,24.66,31,26.48,34.44,26.48Zm-5,16.71c7.05,0,11.61-4,12.19-10.37-3.27-.24-4.8-1.63-5-4.61a13,13,0,0,1-2.36.19c-4.22,0-6.72-2.59-6.72-6.57a12.09,12.09,0,0,1,.34-2.69c-6.39.62-10.27,5.52-10.27,12.29C17.64,38.63,22.2,43.19,29.45,43.19Zm-4-16a1.59,1.59,0,0,1-1.77,1.78,1.62,1.62,0,0,1-1.78-1.78,1.59,1.59,0,0,1,1.78-1.77A1.55,1.55,0,0,1,25.41,27.15Zm4,10.23a1.6,1.6,0,0,1-1.78,1.78,1.62,1.62,0,0,1-1.77-1.78,1.6,1.6,0,0,1,1.77-1.78A1.56,1.56,0,0,1,29.4,37.38Zm8-2.26a1.6,1.6,0,0,1-1.78,1.78,1.62,1.62,0,0,1-1.78-1.78,1.59,1.59,0,0,1,1.78-1.77A1.56,1.56,0,0,1,37.42,35.12Z"
                    fill="#1d1d1b"
                />
            </g>
        </svg>
    );
};

export default CookieIcon;
